import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles, Box } from '@material-ui/core';

import Entry from '../components/Entry';
import { RootState } from '../app/store';
import { getLocalSuggestion, getRemoteSuggestions, suggest } from '../app/room';

interface Props {
  seconds: number;
  guess: string;
  guesses: { who: string; says: string; key: string; }[];
  onGuess: (arg0: string) => void;
}

const useStyles = makeStyles(theme => createStyles({
  wrap: {
    paddingTop: theme.spacing(2),
  },
}));

export const Guest = (props: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrap}>
      <Entry
        isPlaying={true}
        localGuess={props.guess}
        guesses={props.guesses}
        onEntry={props.onGuess}
      />
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  seconds: (state.round.remaining_ms || 0) / 1000,
  guess: getLocalSuggestion(state),
  guesses: getRemoteSuggestions(state).map(({ says, who: { name, id } }) => ({ says, who: name, key: id })),
});

const mapDispatchToProps = (dispatch: any) => ({
  onGuess: (entry: string) => { dispatch(suggest(entry)) },
});

const BoundGuest = connect(mapStateToProps, mapDispatchToProps)(Guest);
export default BoundGuest;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createStyles, Container, Paper, Typography, TextField, Button, Grid } from '@material-ui/core';

import { hasName, updateName } from '../app/settings';
import { RootState } from '../app/store';

const useStyles = makeStyles(theme => createStyles({
  wrap: {
    paddingTop: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(4),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  hidden: boolean;
  setName: (arg0: string) => void;
}

export const Welcome = ({hidden, setName}: Props) => {
  const classes = useStyles();

  const [proposedName, setProposedName] = useState('');

  if (hidden) {
    return (<></>);
  }

  return (
    <Container maxWidth='md' className={classes.wrap}>
      <Paper className={classes.card}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h4'>
              Welcome!
            </Typography>
            <Typography>
              In order to get started, please enter your name.
              You can change this at any time in the settings page.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              variant='outlined'
              label='Your name'
              onChange={e => {setProposedName(e.target.value);}}
            />
          </Grid>

          <Grid item xs={12} className={classes.buttons}>
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              disabled={proposedName.length === 0}
              onClick={(e) => {setName(proposedName);}}
            >
              Start
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  hidden: hasName(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setName: (name: string) => { dispatch(updateName(name)); },
});

export const BoundWelcome = connect(mapStateToProps, mapDispatchToProps)(Welcome);
export default BoundWelcome;

import React from 'react';
import { Box, Typography } from '@material-ui/core';

import WheelWithInfo from './WheelWithInfo';

export const Score = ({ value, of, size }: { value: number; of: number; size?: number; }) => {
  const percent = Math.round((value / of) * 100);

  return (
    <WheelWithInfo value={percent} size={size}>
      <Box>
        <Typography variant='subtitle1'>
          {value}
        </Typography>
        <Typography variant='caption'>
          of {of}
        </Typography>
      </Box>
    </WheelWithInfo>
  );
};

export default Score;

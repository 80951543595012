import React from 'react';
import { Typography } from '@material-ui/core';

import WheelWithInfo from './WheelWithInfo';

interface Props {
  remaining: number;
  total: number;
};

export const Timer = ({remaining, total}: Props) => {
  const minutes = Math.floor(remaining / 60);
  const seconds = remaining > 0 ? Math.floor(remaining % 60) : 0;

  let timeParts = [seconds];

  if (minutes > 0) {
    timeParts = [minutes, ...timeParts];
  }

  const time = timeParts.reduce((acc: string, next: number) => {
    if (acc.length === 0) {
      return `${next}`;
    }

    if (next < 10) {
      return `${acc}:0${next}`;
    }

    return `${acc}:${next}`;
  }, '');

  const percentage = Math.round((remaining / total) * 100);

  return (
    <WheelWithInfo value={percentage}>
      <Typography align='center' variant='subtitle1'>
        { time }
      </Typography>
    </WheelWithInfo>
  );
};

export default Timer;

import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
  slider: {
    padding: theme.spacing(1, 0, 0, 0),
  },
}));

interface Props {
  label: string;
  min?: number;
  step?: number;
  max?: number;
  hoverFormat: (arg0: any) => any;
  value: any;
  onChange: (arg0: any, arg1: any) => void;
}

const LabeledSlider = (props: Props) => {
  const classes = useStyles();

  return (
    <Container disableGutters className={classes.slider}>
      <Typography>
        { props.label }
      </Typography>
      <Slider
        track={false}
        min={ props.min }
        step={ props.step }
        max={ props.max }
        valueLabelDisplay='auto'
        valueLabelFormat={ props.hoverFormat }
        value={ props.value }
        onChange={ (e, v) => { props.onChange(e, v instanceof Array ? v[0] : v); } }
      />
    </Container>
  );
};

export default LabeledSlider;

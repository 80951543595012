import React from 'react';
import { TextField, Box, List, ListItem, ListItemText } from '@material-ui/core';

interface Guess {
  key: string;
  who: string;
  says: string;
}

interface Props {
  isPlaying: boolean;
  localGuess: string;
  guesses: Array<Guess>;

  onEntry: (arg0: string) => void;
};

const Entry = ({isPlaying, localGuess, guesses, onEntry}: Props) => (
  <Box>
    <TextField
      fullWidth
      label='Your guess'
      variant='outlined'
      value={ localGuess }
      disabled={ !isPlaying }
      onChange={ e => { onEntry(e.target.value); } }
    />

    <List>
      {
        guesses.map(guess => (
          <ListItem key={guess.key}>
            <ListItemText>
              { guess.who } is thinking "{ guess.says }"
            </ListItemText>
          </ListItem>
        ))
      }
    </List>
  </Box>
);

export default Entry;


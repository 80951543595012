import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { registerBindings as gameBindings } from './game';
import roomReducer from './room';
import roundReducer, { getKnownTracks, tick as timerTick } from './round';
import { join } from './room';
import spotifyReducer, { initSpotify, registerBindings as spotifyBindings } from './spotify';
import settingsReducer, { getVolume, getDeviceId, registerBindings as settingsBindings } from './settings';

export const store = configureStore({
  reducer: {
    spotify: spotifyReducer,
    room: roomReducer,
    round: roundReducer,
    settings: settingsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// TODO(sclm): This feels a bit clunky, but we need a ticker that will give us info to perform the internal time updates.
//             Minimally, this should probably move to be setup if and when a _host_ presses start. This should otherwise be an update from the host via the websocket?
//             Or don't show it at all on clients...
window.setInterval(() => {
  // store.dispatch(tick());
  store.dispatch(timerTick(Date.now()));
}, 500);  // Every half-second

// Connect to the propper room stream.
store.dispatch(join());

// Connect to the API.
store.dispatch(initSpotify(process.env.REACT_APP_API_ENDPOINT || ""));

// Connect the store to bindings for dispatches and syncing to other clients.
settingsBindings(store);
spotifyBindings(store, getVolume);
gameBindings(store, getKnownTracks, getDeviceId);

import React, { useState } from 'react';
import { makeStyles, createStyles, CssBaseline, AppBar, Toolbar, IconButton, Drawer } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';

import RoomCode from './components/RoomCode';
import VolumeControl from './components/VolumeControl';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Welcome from './pages/Welcome';

const useStyles = makeStyles(theme => createStyles({
  logo: {
    padding: 10,
    maxHeight: 60,
  },
  siteName: {
    flexGrow: 1,  
  },
  volume: {
    margin: theme.spacing(1),
    width: 100,
  },
  roomCode: {
    margin: theme.spacing(1),
  },
}));

const App = () => {
  const classes = useStyles();
  const [showSettings, expandSettings] = useState(false);

  return (
  <>
    <CssBaseline />
    <AppBar position='relative'>
      <Toolbar>
        <img src='/icon.svg' alt='Quiz.Camp logo' className={ classes.logo } />
        <div className={ classes.siteName } />
        <RoomCode className={ classes.roomCode } />
        <VolumeControl className={ classes.volume } />
        <IconButton color='inherit' onClick={() => expandSettings(!showSettings)}>
          <SettingsIcon />
        </IconButton>
      </Toolbar>
    </AppBar>

    <Drawer open={ showSettings } onClose={() => expandSettings(false)} anchor="right">
      <Settings />
    </Drawer>

    <main>
      <Welcome />
      <Home />
    </main>
  </>
  );
};

export default App;

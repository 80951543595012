import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Paper, Container, Tab, makeStyles, createStyles } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';

import { isActive as isActiveRound } from '../app/round';
import { hasName } from '../app/settings';
import { RootState } from '../app/store';
import Setup from './Setup';
import Game from './Host';
import Guest from './Guest';
import Join from './Join';
import { TabPanel } from '@material-ui/lab';

interface Props {
  hidden: boolean;
  isActive: boolean;
};

const useStyles = makeStyles(theme => createStyles({
  background: {
    paddingTop: theme.spacing(4),
  },
  card: {
    padding: theme.spacing(4, 2),
  },
}));

const Home = ({
  hidden,
  isActive,
}: Props) => {
  const classes = useStyles();
  const [tabSelected, setTab] = useState('host');

  // If told to, we should supress our render.
  if (hidden) {
    return (<></>);
  }

  if (isActive) {
    return (
      <Game />
    );
  }

  return (
    <Container maxWidth='md' fixed className={classes.background}>
      <Paper>
        <TabContext value={tabSelected}>
        <TabList
          variant='fullWidth'
          onChange={(e, t) => setTab(t)}
        >
          <Tab label='Setup' value='host' />
          <Tab label='Suggest' value='suggestions' />
          <Tab label='Change Room' value='change-room' />
        </TabList>

        <TabPanel value='host'>
          <Setup />
        </TabPanel>

        <TabPanel value='suggestions'>
          <Guest />
        </TabPanel>

        <TabPanel value='change-room'>
          <Join />
        </TabPanel>
        </TabContext>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  hidden: !hasName(state),
  isActive: isActiveRound(state),
});

export const BoundHome = connect(mapStateToProps)(Home);
export default BoundHome;

import React, { useState } from 'react';
import { Container, Grid, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import { isAuthenticated, getAuthRedirect } from '../app/spotify';
import { start } from '../app/round';
import { RootState } from '../app/store';

const BuiltInPlaylists = [{
  uri: 'https://open.spotify.com/playlist/6EQtmQWWeINDwRrIWYSWSE',
  name: '90s Alt',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/36kcm7JX87VVE51Vuz5csx',
  name: '80s',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/58P7NiwxvByEnfD9ieHDPA',
  name: 'Yacht Rock',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/4El3Bt16vypFIWaWJO8xHf',
  name: 'Scene Dream',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/49ee1VZSn1UtQzmXyBF690',
  name: '2000s Pop',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/2yMldgbHGfOoEz7bpgbekK',
  name: 'Late \'00s - Early \'10s Indie',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/0m8pGuOOFE0jmjuZ6PEZMR',
  name: 'Broadway',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/12wKSg2FfPOEKvbWFTAfQp',
  name: '2010s Pop',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/4iBvhy1itcnqrqaJ5dTM3G',
  name: '90s & 00s R&B',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/3uAzlOmmDRj2dk6Zc7t89p',
  name: '90s & 00s Rap & Hip-Hop',
  author: 'Marina',
}, {
  uri: 'https://open.spotify.com/playlist/5J9udSFw8rA7ZgLE8Y5VXO?si=4LDBH2DdSSWCqtklDNIIvw',
  name: 'Bops Jams Bangers',
  author: 'Jose',
}, {
  uri: 'https://open.spotify.com/playlist/3T5YQHTLdULlyRcqpYhgsC?si=4AvzTM0ZRT-S5UHpnE1bgA',
  name: 'Karaoke Go Tos',
  author: 'Jose',
}, {
  uri: 'https://open.spotify.com/playlist/37i9dQZF1DXardnHdSkglX?si=1-1cUvCsQVKkFx0BtCuWqw',
  name: 'Bossa Nova Covers',
  author: 'Spotify',
}, {
  uri: 'https://open.spotify.com/playlist/37i9dQZF1DWXti3N4Wp5xy?si=WMAOFBGLRseTCgYqfV252A',
  name: 'Pop Party',
  author: 'Spotify',
}, {
  uri: 'https://open.spotify.com/playlist/37i9dQZF1DWTJ7xPn4vNaz?si=O5umH65UQYKAAPMyD7P3vA',
  name: '70s All Out',
  author: 'Spotify',
}, {
  uri: 'https://open.spotify.com/playlist/1gSieFx6egfTI95udmjfTC?si=SJ2AmYJaTd26hBsgf5a6yQ',
  name: 'Pop Punk-Style Covers',
}, {
  uri: 'https://open.spotify.com/playlist/6Jp1ddYHeTa7hRsxfwFNAU?si=k93FAGHgQiqDgRkw0Cz4Sg',
  name: 'It\'s Not Just A Phase, Mom - Scene Songs of the 00s',
}, {
  uri: 'https://open.spotify.com/playlist/6McViXGN6adYdp5r1sP08p?si=OKstZjKgRWupI-pL0Y8FKw',
  name: 'Early 2000s Emo',
}, {
  uri: 'https://open.spotify.com/playlist/5dg1VcuBlk42Cq3yZyrVIE?si=zvqIeA_mRSKUzDjrKn4flw',
  name: 'Edgy Bitch',
}, {
  uri: 'https://open.spotify.com/playlist/75lv5vHB4LzcqKrs3xhQcA?si=RQTVKJDhR3erjQTUkmDxdg',
  name: 'Ex-Emo Kid (Current Music for the Former Emo Listener)',
}, {
  uri: 'https://open.spotify.com/playlist/3LBiIBqF5VgiqimlU5U5lZ?si=0NRRV6erRRewKeRNCdhwrQ',
  name: 'Nasty Women',
}, {
  uri: 'https://open.spotify.com/playlist/6Z2CGgVc8Ir70RCeKsNA2J?si=G-UvlZVBT3-_tK85RqOHXQ',
  name: 'On Some Sad Boi Shit',
}, {
  uri: 'https://open.spotify.com/playlist/3H9nMYDyX8Q0kV8UVQTdU2?si=xWoK2TYqRHKNP2y5QOIcZQ',
  name: 'Unofficial Punk Goes Pop',
}, {
  uri: 'https://open.spotify.com/playlist/4lLhxYyD9i3GlSYrcMsTYF?si=0QoKhA1xQQeqi217Jmg4Wg',
  name: 'Puttin\' The Funk Back In Defunct',
}, {
  uri: 'https://open.spotify.com/playlist/10sRbGFrwV9Be2fqsazKVp?si=4y0omoiITG6PDKZhdfehlQ',
  name: 'Pop Punk Duets', 
}, {
  uri: 'https://open.spotify.com/playlist/3iOjCSGQZ7lZCpbY4lOmdY?si=BOHSjTmeRRuhSRhM0QB1TQ',
  name: 'Getting Out Of This Town',
}, {
  uri: 'https://open.spotify.com/playlist/51GhQFYoI6YPb86dn0SDCi?si=jqoUlukwQkiDTCUHZyY7Rw',
  name: 'Hoe Anthems',
}, {
  uri: 'https://open.spotify.com/playlist/24iO8m5MsilvylxRaL6fUX?si=IN9SPb_rQnWQ5ZSbCjPa8g',
  name: 'Yes Gawd, Henny',
}, {
  uri: 'https://open.spotify.com/playlist/1ED8C1VDBKF7auPLdWqOPd?si=yeJelP-6Sna6XAcD0RD3bA',
  name: 'Girl Groups',
}, {
  uri: 'https://open.spotify.com/playlist/0efd5khnj1riV4NBnhH5Gs?si=_iSkDK7jQXeaobWcuj-tsw',
  name: 'Boy Bands',
}, {
  uri: 'https://open.spotify.com/playlist/4hBUke2lGCJ2CWs53ilg06?si=57cAYFAeRAuK4hKmvTIoRA',
  name: 'When Ya Man Is Trash',
}, {
  uri: 'https://open.spotify.com/playlist/12qG5z55yE63i6yBnErZ7A?si=jCAhbmzvQyu-4fz5MfMGjg',
  name: '00s and 10s One Hit Wonders',
}, {
  uri: 'https://open.spotify.com/playlist/4kKqk5d7NpW5ipoyBc3nun?si=3rMKw22rS8yItppYvgU9yg',
  name: 'Scarionette',
}, {
  uri: 'https://open.spotify.com/playlist/530mXyog41njOqtlsuwK8b?si=t8tGYExzSsu_vKUJR4RwUg',
  name: 'Pop Dance Party',
}].sort((l, r) => (l.name < r.name ? -1 : 1));

interface IProps {
  isAuthenticated: boolean;
  authRedirect: string;

  onStart: (uri: string) => void;
};

export const Setup = ({
  isAuthenticated,
  authRedirect,
  onStart,
}: IProps) => {
  const [autocompleteUsed, setAutocompleteUsed] = useState(false);
  const [uri, setUri] = useState("");

  return (
    <Container>
      <Grid container spacing={2}>
        {isAuthenticated ? <>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={BuiltInPlaylists}
              getOptionLabel={(o: any) => (o.author?.length > 0 ? `${o.name} by ${o.author}` : o.name)}
              onChange={(e, v, r) => {
                if (r === 'clear') {
                  setAutocompleteUsed(false);
                } else if (r === 'select-option' && v !== null) {
                  setUri(v.uri);
                  setAutocompleteUsed(true);
                }
              }}
              renderInput={(params: any) => (
                <TextField {...params} label='Curated Playlists' variant='outlined' />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              label='Playlist'
              value={uri}
              helperText='Enter any public playlist with the URI or URL to play using it'
              onChange={(e) => { setUri(e.target.value); }}
              disabled={autocompleteUsed}
            />
          </Grid>

          <Grid item xs={12}>
            <Button color='primary' variant='contained' onClick={() => onStart(uri)}>Start</Button>
          </Grid>
        </> :
          <Grid item xs={12}>
            <Button color='primary' variant='contained' href={authRedirect}>Login to Spotify</Button>
          </Grid>}
      </Grid>
    </Container>
  );
};

const dispatchCallbacks = (dispatch: any) => ({
  onStart: (uri: string) => { dispatch(start(uri)) },
});

const stateToProps = (state: RootState) => ({
  isAuthenticated: isAuthenticated(state),
  authRedirect: getAuthRedirect(state),
});

const BoundSetup = connect(stateToProps, dispatchCallbacks)(Setup);
export default BoundSetup;

import { createAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export const correct = createAction('game/correct');

export const tick = createAction('game/tick', (at: number = Date.now()) => ({
  payload: {
    at,
  },
}));

interface Subscribable {
  subscribe: (arg0: () => void) => void;
  getState: () => RootState;
  dispatch: (arg0: any) => void;
}

interface CorrectGuess {
  uri: string;
  guesserId: string;
}
type trackSelector = (arg0: RootState) => CorrectGuess[];
type localIdSelector = (arg0: RootState) => string;

export const registerBindings = (store: Subscribable, getKnownTracks: trackSelector, getLocalId: localIdSelector) => {
  let alreadyKnown = new Set<CorrectGuess>();
  store.subscribe(() => {
    // Check to see if we need to emit the correct action, for a correct answer.
    const state = store.getState();
    getKnownTracks(state).forEach(guess => {
      // We only want to dispatch the action if this is new to us now.
      if (alreadyKnown.has(guess)) {
        return;
      }

      // And lets make sure we know next time.
      alreadyKnown.add(guess);

      //  If local, dispatch the `correct` action.
      if (guess.guesserId === getLocalId(state)) {
        store.dispatch(correct());
      }
    });
  });
};

import React from 'react';
import { connect } from 'react-redux';
import { Paper, List, ListItem, ListItemIcon, ListItemText, makeStyles, createStyles, Chip } from '@material-ui/core';
import { PlayArrow, Pause, Replay } from '@material-ui/icons';

import { RootState } from '../app/store';
import { getTracksWithKnownFlag, isFinishedGame } from '../app/round';
import { getMostRecentURI, play, pause } from '../app/spotify';

const useStyles = makeStyles(theme => createStyles({
  list: {
    padding: 0,
  },
  song: { 
    textAlign: 'center',
  },
  unknown: {},
  incorrect: {
    color: theme.palette.error.main,
  },
  correct: {
    color: theme.palette.success.main,
  },
}));

interface Song {
  uri: string;
  name: string;
  artists?: string[];
  start_ms: number;
  known: boolean;
};

interface Props {
  hasEnded: boolean;
  songs: Song[];
  currentTrack: string;
  play: (arg0: string, arg1: number) => void;
  pause: () => void;
};

const Songs = ({
  hasEnded,
  songs,
  currentTrack,
  play,
  pause,
}: Props) => {
  const classes = useStyles();

  return (
    <Paper>
      <List className={ classes.list }>
        { songs.map((song, index) => {
          const revealed = song.known || hasEnded;
          const isPlaying = song.uri === currentTrack;
          const number = index + 1; // Because CS starts at 0.
          const textClass = song.known ? classes.correct : (hasEnded ? classes.incorrect : classes.unknown);

          const title = revealed ? song.name : number.toString();
          const secondary = revealed ? (song.artists === undefined ? [] : song.artists).join(', ') : ' ';

          // Select an icon to indicate which one to use.
          const icon = isPlaying ? <Pause /> : (song.known ? <Replay /> : <PlayArrow />);

          return (
            <ListItem
              button
              className={classes.song}
              selected={isPlaying}
              onClick={() => (isPlaying ? pause() : play(song.uri, song.start_ms))}
              key={song.uri}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              {revealed && <Chip size='small' label={number} />}
              <ListItemText className={textClass} primary={title} secondary={secondary} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

const mapStateToProps = (state: RootState) => ({
  hasEnded: isFinishedGame(state),
  songs: getTracksWithKnownFlag(state),
  currentTrack: getMostRecentURI(state) || '',
});

const mapDispatchToProps = (dispatch: any) => ({
  play: (uri: string, offset: number) => { dispatch(play(uri, offset)) },
  pause: () => { dispatch(pause()) },
});

export const BoundSongs = connect(mapStateToProps, mapDispatchToProps)(Songs);
export default BoundSongs;

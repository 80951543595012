import React from 'react';
import { Grid, Button, makeStyles, createStyles } from '@material-ui/core';
import { Stop, Pause, PlayArrow } from '@material-ui/icons';

import Score from './Score';
import Timer from './Timer';

export enum Status {
  Playing = 'playing',
  Paused = 'paused',
  GameOver = 'gameover',
}

interface Props {
  // For the scoring
  score: number;
  songCount: number;

  // For the timer
  remainingSeconds: number;
  durationSeconds: number;

  //  For the control buttons
  status: Status;
  onToggle: () => void;
  onResign?: () => void;
}

const useStyles = makeStyles(theme => createStyles({
  item: {
    textAlign: 'center',
  },
}));

export const GameControls = ({
  score,
  songCount,
  remainingSeconds,
  durationSeconds,
  status,
  onToggle,
  onResign,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs className={classes.item}>
        <Score value={score} of={songCount} />
      </Grid>

      <Grid item xs className={classes.item}>
        <Timer remaining={remainingSeconds} total={durationSeconds} />
      </Grid>

      { status !== Status.GameOver && 
      <Grid item xs className={classes.item}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              onClick={ () => onToggle() }
              startIcon={ status === Status.Playing ? <Pause /> : <PlayArrow /> }
            >
              { status === Status.Playing ? 'Pause' : 'Play' }
            </Button>
          </Grid>

          {onResign !== undefined &&
            <Grid item xs={12}>
              <Button
                onClick={ () => onResign() }
                startIcon={ <Stop /> }
              >
                Resign
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
      }
    </Grid>
  );
};

export default GameControls;

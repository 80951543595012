import React from 'react';
import { Container, FormControlLabel, TextField, Switch, makeStyles, createStyles } from '@material-ui/core';
import LabeledSlider from '../components/LabeledSlider';
import { connect } from 'react-redux';
import { RootState } from '../app/store';
import {
  updateName,
  getName,
  updateDuration,
  getDuration,
  updateGap,
  getGap,
  updateReplay,
  getReplay,
  updateShuffle,
  getShuffle,
} from '../app/settings';

const useStyles = makeStyles(theme => createStyles({
  contentWell: {
    paddingTop: theme.spacing(3),
  },
  name: {
    paddingBottom: theme.spacing(2),
  },
}));

const formatTime = (ms: number) => {
  const minutes = Math.floor(ms / 60000);
  const seconds = ms > 0 ? Math.floor((ms/1000) % 60) : 0;

  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

interface Props {
  name: string;
  setName: (arg0: string) => any,
  shuffle: boolean;
  setShuffle: (arg0: boolean) => any,
  duration: number,
  setDuration: (arg0: number) => any,
  gap: number,
  setGap: (arg0: number) => any,
  replayTime: number,
  setReplayTime: (arg0: number) => any,
};

export const Setup = (props: Props) => {
  const classes = useStyles();

  return (
    <Container className={ classes.contentWell }>
      <TextField
        className={ classes.name }
        label='Your name'
        value={ props.name }
        onChange={ (e) => props.setName(e.target.value) }
      />

      <LabeledSlider
        label='Duration'
        min={1000}
        step={1000}
        max={20000}
        hoverFormat={ formatTime }
        value={ props.duration }
        onChange={ (e, v) => { props.setDuration(v); } }
      />

      <LabeledSlider
        label='Pause between songs'
        min={1000}
        step={1000}
        max={10000}
        hoverFormat={ formatTime }
        value={ props.gap }
        onChange={ (e, v) => { props.setGap(v-1000); } /* This is 1 second less because there is generally a gap that the network/buffering imposes. */ }
      />
      
      <LabeledSlider
        label='Extra time to revisit clips'
        min={0}
        step={1000}
        max={1200000}
        hoverFormat={ formatTime }
        value={ props.replayTime }
        onChange={ (e, v) => { props.setReplayTime(v); } }
      />

      <Container disableGutters>
        <FormControlLabel
          control={
            <Switch
              checked={ props.shuffle }
              onChange={ e => { props.setShuffle(e.target.checked )} }
            />
          }
          label="Shuffle?"
        />
      </Container>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  name: getName(state),
  shuffle: getShuffle(state),
  duration: getDuration(state),
  gap: getGap(state),
  replayTime: getReplay(state),
});

const mapDispatchToProps = (dispatch: (arg0: any)=>{}) => ({
  setName: (name: string) => { dispatch(updateName(name)); },
  setDuration: (ms: number) => { dispatch(updateDuration(ms)); },
  setGap: (ms: number) => { dispatch(updateGap(ms)); },
  setReplayTime: (ms: number) => { dispatch(updateReplay(ms)); },
  setShuffle: (enabled: boolean) => { dispatch(updateShuffle(enabled)); },
});

const BoundSetup = connect(mapStateToProps, mapDispatchToProps)(Setup);
export default BoundSetup;

import * as firebase from 'firebase/app';
import 'firebase/analytics';

const conf = {
  apiKey: "AIzaSyDvSsSsBhtjj8g7mJC2MAFWo8sqcO6QkuY",
  authDomain: "quiz-camp.firebaseapp.com",
  databaseURL: "https://quiz-camp.firebaseio.com",
  projectId: "quiz-camp",
  storageBucket: "quiz-camp.appspot.com",
  messagingSenderId: "93256231017",
  appId: "1:93256231017:web:fcdcb28c3fe22957db1af8",
  measurementId: "G-TMBH0QYW7W"
};

firebase.initializeApp(conf);
export const analytics = firebase.analytics();

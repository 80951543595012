import { createReducer, createAction } from '@reduxjs/toolkit';
import Sockette from 'sockette';
import { randomAlphanumeric } from '../random';
import { RootState } from './store';
import { correct }  from './game';
import { getName, getDeviceId } from './settings';

interface Who {
  id: string;
  name: string;
}

interface Suggestion {
  who: Who;
  says: string;
}

let relay = null as Sockette|null;

interface State {
  code: string;
  suggestions: {
    local: string;
    remote: Suggestion[];
  };
};

const generateRoom = () => randomAlphanumeric(4);

const joinAction = createAction('room/join', (code: string) => ({
  payload: {
    code,
  },
}));

export const join = (code: string = generateRoom()) => {
  return (dispatch: any, getState: () => RootState) => {
    code = code.toUpperCase();
    const currentCode = getRoomCode(getState());

    if (currentCode !== code) {
      if (relay !== null) {
        relay.close();
      }
      relay = new Sockette(`${process.env.REACT_APP_WS_RELAY}/room/${code}`, {
        onmessage: (ev => {
          let {says,who}: Suggestion = JSON.parse(ev.data);

          // Bypass anything that is just a reflection of ourselves.
          if (who.id === getDeviceId(getState())) {
            return;
          }

          dispatch(suggestAction(says || '', who));
        }),
      });
    }

    return dispatch(joinAction(code));
  };
};

export const getRoomCode = (state: RootState) => (state.room.code);

export const suggestAction = createAction('room/suggest', (says: string, who: Who, isHost:boolean = false) => {
  return {
    payload: {
      isHost,
      who,
      says,
    },
  };
});

export const suggest = (says: string) => {
  return (dispatch: any, getState: () => RootState) => {
    let who = {
      id: getDeviceId(getState()),
      name: getName(getState()),
    };

    if (relay === null) {
      return dispatch(suggestAction(says, who, true));
    }

    relay.send(JSON.stringify({
      who,
      says,
    }));

    return dispatch(suggestAction(says, who, true));
  };
};

export const getLocalSuggestion = (state: RootState) => state.room.suggestions.local;
export const getRemoteSuggestions = (state: RootState) => state.room.suggestions.remote;
export const getMembers = (state: RootState) => state.room.suggestions.remote.map(s => s.who.name);

export const reducer = createReducer(
  {
    code: generateRoom(),
    suggestions: {
      local: '',
      remote: [],
    },
  } as State,
  builder => {
    builder
    .addCase(joinAction, (state, action) => {
      state.code = action.payload.code.toUpperCase();
      state.suggestions.remote = [];
    })
    .addCase(suggestAction, (state, action) => {
      if (action.payload.isHost) {
        state.suggestions.local = action.payload.says;
      } else {
        // Because we can't use the ES Map<string, string> built in, we have to fake it with an array.
        // There is some limited discussion about this in https://github.com/reduxjs/redux/issues/1499 about how we cannot use `createReducer` with it.
        state.suggestions.remote.unshift({
          who: action.payload.who,
          says: action.payload.says,
        });

        // To avoid dupes, we filter them out here. While we're at it, we drop any empty suggestions.
        let seen = new Set<string>();
        state.suggestions.remote = state.suggestions.remote.filter(e => {
          const keep = !seen.has(e.who.id) && e.says.length > 0;
          seen.add(e.who.id);
          return keep;
        });
      }
    })
    .addCase(correct, (state) => {
      state.suggestions.local = '';
    });
  },
);

export default reducer;

const featuringMatchers = [
  /(\(|-)\s*feat(uring)?.*\)?/i,
  /(\(|-)\s*with.*\)?/i,
  /(\(|-)\s*from.*\)?/i,
  /(\(|-).*cover\)?/i,
  /(\(|-).*version\)?/i,
  /-\s*\d{0,4}\s*(original|revival) cast/i,
  /-\s*main theme/i,
];

const contractions = [{
  regex: /(\w+)in'/g,
  replace: '$1ing',
},{
  regex: /i'm/i,
  replace: 'i am',
}, {
  regex: /you're/i,
  replace: 'you are',
}, {
  regex: /(\s)u(\s)/ig,
  replace: '$1you$2',
}, {
  regex: /&/g,
  replace: 'and',
}];

export function matcher (correct: string, guess: string) {
  const correctTokens = tokenize(correct);
  const cleaned = normalize(guess);

  for (const token of correctTokens) {
    if (token === cleaned) {
      return true;
    }
  }
  return false;
};

export function tokenize(songName: string): string[] {
  // Remove things that were `(featuring XX)` and the like.
  featuringMatchers.forEach(m => {
    songName = songName.replace(m, '');
  });

  return songName
    .split('(').map(s => s.replace(/\)/, '')) // Break it apart to have the parens in separate options. This allows alternate titles.
    .flatMap(handleContractions)
    .map(normalize);
};

function handleContractions(s: string): string[] {
  let fullSet = [s];

  contractions.forEach(({regex, replace}) => {
    if (regex.test(s)) {
      fullSet.push(s.replace(regex, replace));
    }
  });

  return fullSet;
};

function normalize(c: string): string {
  return c
    .toLowerCase()  // Remove case sensitivity.
    .replace(/^the +/, '') // No leeding 'the' is required.
    .replace(/-\s+\d{0,4}\s*remaster(ed)?$/, '') // Remove the markers we find for remasters
    .replace(/-\s+.*\s*(version|edit)?/, '') // Remove remix info.
    .normalize('NFD') // We need to decompose things that can be combining characters so that we can...
    .replace(/[\u0300-\u036f]/g, '') // remove all combining characters that would produce accents or a ñ.
    .replace(/[\u2000-\u206F\u2E00-\u2E7F\\'!"#%&()*+,\-./:;<=>?@[\]^_`{|}~]/g, '') // Remove punctiation. There's a lot of it, but none is kept.
    .replace(/\s/g, ''); // No space is considered significant.
};

export default matcher;

import { createSlice, createSelector, createAction } from '@reduxjs/toolkit';
import shuffle from 'shuffle-array';
import SpotifyWebApi from 'spotify-web-api-js';
import { RootState } from './store';
import { play as playTrack, pause as pauseTrack, clear as clearTrack, playing, stopped, getMostRecentURI, getIsPlaying } from './spotify';
import { suggestAction } from './room';
import matcher from '../songMatcher';
import { randomInt } from '../random';

// How much time should we prevent from using at the start and end of a track?
const StartBuffer = 10000; // ms
const EndBuffer = 10000; // ms

const urlFormatPlaylist = /https:\/\/open.spotify.com\/playlist\/(?<id>[A-Za-z0-9]+).*/;
const uriFormatPlaylist = /spotify:playlist:(?<id>[A-Za-z0-9]+)/;
const bareId = /^\s*(?<id>[A-Za-z0-9]+)\s*$/

const getSpotifyID = (uri: string) => {
  let matches = [
    urlFormatPlaylist,
    uriFormatPlaylist,
    bareId,
  ].map(matcher => {
    const urlMatch  = uri.match(matcher);

    if (urlMatch?.groups?.id != null)  {
      return {
        valid: true,
        id: urlMatch.groups.id,
      }
    }

    return {
      valid: false,
      id: "",
    };
  }).filter(({valid}) => (valid));

  if (matches.length === 0) {
    return {valid: false, id: ""};
  }

  return matches[0];
};

export enum GameStatus {
  Unstarted = 'unstarted',
  Playing = 'playing',
  Paused = 'paused',
  Resigned = 'resigned',
  Lost = 'lost',
  Won = 'won',
};

interface Track {
  uri: string;
  name: string;
  duration_ms: number;
  start_ms: number;
  artists: Array<string>;
}

interface KnownTrack {
  uri: string;
  guesserId: string;
}

interface CommonState {
  updatedInstant: number;
  playerActionLastInstant: number;
  known: KnownTrack[];
  tracks: Track[];
};

interface PlayingState extends CommonState {
  status: GameStatus.Playing;
  remaining_ms: number;
  duration_ms: number;
};

interface HaltedState extends CommonState {
  status: GameStatus.Paused | GameStatus.Resigned | GameStatus.Lost;
  remaining_ms: number;
  duration_ms: number;
};

interface UnstartedState extends CommonState {
  status: GameStatus.Unstarted;
  remaining_ms: null;
  duration_ms: null;
};

interface WinningState extends CommonState {
  status: GameStatus.Won;
  remaining_ms: number;
  duration_ms: number;
}

type State = UnstartedState | PlayingState | WinningState | HaltedState;

export const getStatus = (state: RootState) => state.round.status;
export const isActive = (state: RootState) => state.round.status !== GameStatus.Unstarted;
export const isFinishedGame = (state: RootState) => state.round.status !== GameStatus.Unstarted && state.round.status !== GameStatus.Playing && state.round.status !== GameStatus.Paused;

export const getDuration = (state: RootState) => state.round.duration_ms;
export const getRemainingMs = (state: RootState) => state.round.remaining_ms;

export const getTracks = (state: RootState) => state.round.tracks;
export const getKnownTracks = (state: RootState) => state.round.known;
export const getKnownURIs = createSelector([getKnownTracks], (tracks) => tracks.map(a => a.uri));
export const getTracksWithKnownFlag = createSelector([getTracks, getKnownURIs], (tracks, known) => tracks.map(t => ({...t, known: known.indexOf(t.uri) > -1})));

export const clear = createAction('round/clear');

// TODO(sclm): Rename this var? Since we wrap it for most things...
const slice = createSlice({
  name: 'round',
  initialState: {
    status: GameStatus.Unstarted,
    remaining_ms: null,
    duration_ms: null,
    updatedInstant: Date.now(),
    playerActionLastInstant: 0,
    tracks: [] as Array<Track>,
    known: [],
  } as State,
  reducers: {
    start: (state, action) => {
      state.status = GameStatus.Playing;
      state.duration_ms = action.payload.duration_ms;
      state.remaining_ms = action.payload.duration_ms;
      state.tracks = action.payload.tracks;
      state.known = [];
    },
    pause: (state) => {
      state.status = GameStatus.Paused;
    },
    resume: (state) => {
      state.status = GameStatus.Playing;
    },
    resign: (state) => {
      state.status = GameStatus.Resigned;
    },
    // This is a special action. This one is where our interval will give us the info to update the timer state. User actions should not trigger it.
    tick: (state, action) => {
      const elapsed = action.payload.at - state.updatedInstant;
      state.updatedInstant = action.payload.at;

      // Skip for non `ActiveState` situations.
      if (state.status === GameStatus.Resigned || state.status === GameStatus.Paused || state.status === GameStatus.Unstarted || state.status === GameStatus.Lost || state.status === GameStatus.Won) {
        return;
      }

      state.remaining_ms -= elapsed;

      // Check if we're overtime.
      if (state.remaining_ms <= 0) {
        state.status = GameStatus.Lost;
      }
    },
  },
  extraReducers: builder => {
    builder
    .addCase(clear, state => {
      state.status = GameStatus.Unstarted;
      state.duration_ms = null;
      state.remaining_ms = null;
      state.tracks = [];
      state.known = [];
    })
    .addCase(playing, (state, action) => {
      state.playerActionLastInstant = action.payload.at;

      if (state.status === GameStatus.Paused) {
        // @ts-ignore
        state.status = GameStatus.Playing;
      }
    })
    .addCase(stopped, (state, action) => {
      state.playerActionLastInstant = action.payload.at;
    })
    .addCase(suggestAction, (state, action) => {
      // If we are not ongoing...we should not consume the answer.
      if (state.status !== GameStatus.Playing) {
        return;
      }

      const knownURIs = state.known.map(t => t.uri);

      // Filter down the tracks and record correct ones
      state.tracks
        .filter(({uri}) => !knownURIs.includes(uri))
        .filter(({name: songName}) => matcher(songName, action.payload.says))
        .forEach(correctSong => {
          state.known.unshift({
            uri: correctSong.uri,
            guesserId: action.payload.who.id,
          });
        });

      if (state.known.length >= state.tracks.length) {
        // We're changing the state for the next time around.
        // @ts-ignore
        state.status = GameStatus.Won;
      }
    });
  }
});

export const {
  resume,
} = slice.actions;

export const start = (uri: string) => {
  return (dispatch: any, getState: any) => {
    const {
      trackDuration_ms,
      gapDuration_ms,
      replayTime_ms,
      shuffle: shouldShuffle
    } = getState().settings;
    const token = getState().spotify.token;
    let sdk = new SpotifyWebApi();
    sdk.setAccessToken(token);

    const { id: playlist_id, valid } = getSpotifyID(uri);

    if (!valid) {
      dispatch({
        type: 'SPOTIFY_INVALID_PLAYLIST_ENTRY',
      })
    }

    sdk.getPlaylistTracks(playlist_id).then(r => {
      //  We construct a map of the Tracks, allowing for us to make sure there are no duplicates.
      let indexedTracks = new Map<string, Track>();

      r.items.forEach((track: SpotifyApi.PlaylistTrackObject) => {
        const duration_ms = track.track.duration_ms;
        const start_ms = randomInt(StartBuffer, duration_ms - EndBuffer - trackDuration_ms);

        let artists = [];

        if (track.track.type === 'track') {
          artists = track.track.artists.map(artistObject => artistObject.name);
        } else {
          artists = [track.track.show.name];
        }

        indexedTracks.set(track.track.uri, {
          uri: track.track.uri,
          name: track.track.name,
          duration_ms,
          start_ms,
          artists,
        });
      });

      let tracks = Array.from(indexedTracks.values());

      if (shouldShuffle) {
        shuffle(tracks);
      }

      const trackCount = tracks.length;
      const totalDuration = (trackDuration_ms+gapDuration_ms)*trackCount + replayTime_ms;

      dispatch(slice.actions.start({duration_ms: totalDuration, tracks}));
      dispatch(gotoNext());
    }, e => { console.error(e); });
  };
}

export const pause = () => {
  return (dispatch: any, getState: any) => {
    dispatch(pauseTrack());
    dispatch(slice.actions.pause());
  }
}

export const resignAction = slice.actions.resign;
export const resign = () => {
  return (dispatch: any) => {
    dispatch(pauseTrack());
    dispatch(clearTrack());
    dispatch(slice.actions.resign());
  }
}

export const tick = (at: number) => {
  return (dispatch: any, getState: () => RootState) => {
    const status = getStatus(getState());
    if (status === GameStatus.Resigned || status === GameStatus.Paused || status === GameStatus.Unstarted || status === GameStatus.Lost || status === GameStatus.Won) {
      return;
    }

    dispatch(slice.actions.tick({at}));

    // If we're ticking while a guess was just accepted or we're over time we need to check and advance the music.
    const lastTrack = getMostRecentURI(getState());
    if (lastTrack !== undefined &&  getKnownURIs(getState()).includes(lastTrack)) {
      dispatch(gotoNext());
    }

    // Check to see if we need to pause the music or move to a next song.
    const {
      trackDuration_ms: playLength,
      gapDuration_ms: pauseLength,
    } = getState().settings;
    const isPlaying = getIsPlaying(getState());
    const playerActionLastInstant = getState().round.playerActionLastInstant;

    if (isPlaying && (at - playerActionLastInstant > playLength)) {
      dispatch(pauseTrack());
    } else if (!isPlaying && (at - playerActionLastInstant > pauseLength)) {
      dispatch(gotoNext());
    }
  };
}

const gotoNext = () => {
  return (dispatch: any, getState: () => RootState) => {
    const activeTrackURI = getMostRecentURI(getState());
    const knownTracks = getKnownURIs(getState());
    const tracks = getState().round.tracks
      .map((t: Track) => ({
        ...t,
        active: t.uri === activeTrackURI,
        known: knownTracks.includes(t.uri),
      }));
    
    if (knownTracks.length === tracks.length) {
      return;
    }

    // We start with what was active...
    let nextTrackIdx = tracks.findIndex((t: {active: boolean}) => t.active);

    // When we start up, the active track is almost certainly not in the playlist. But we'll increment that to 0 in a sec.

    do {
      // Add 1, so we get to the next one...
      nextTrackIdx++;

      // But if we go over the length, we need to wrap.
      if (nextTrackIdx < 0 || nextTrackIdx > tracks.length-1) {
        nextTrackIdx = 0;
      }
    } while(tracks[nextTrackIdx].known)
    
    dispatch(playTrack(tracks[nextTrackIdx].uri, tracks[nextTrackIdx].start_ms));
  };
};

export default slice.reducer;

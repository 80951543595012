const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export const randomInt = (min: number, max: number) => Math.floor((Math.random() * (max-min)) + min);

export const randomSelect = (from: string|Array<any>) => from[randomInt(0, from.length)];

export const randomAlphanumeric = (len: number) => {
  let str = '';
  
  for (let i = 0; i < len; i++) {
    str += randomSelect(alphanumeric);
  }
  
  return str;
};

export default randomInt;
import React, { FunctionComponent } from 'react';
import { Box, CircularProgress, makeStyles, createStyles } from '@material-ui/core';

interface Props {
  value: number;
  size?: number;
}

const useStyles = makeStyles(theme => createStyles({
  container: {
    position: 'relative',
    display: 'inline-flex',
  },
  value: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const WheelWithInfo: FunctionComponent<Props> = ({value, size, children}) => {
  const classes = useStyles();

  if (size === undefined) {
    size = 80;
  }

  return (
    <Box className={classes.container}>
      <CircularProgress
        variant='static'
        value={value}
        size={size}
        />
      <Box className={classes.value}>
        {children}
      </Box>
    </Box>
  );
};

export default WheelWithInfo;
import React from 'react';
import { makeStyles, createStyles, Theme, Grid, Box, Container, Paper, Button } from '@material-ui/core';
import { connect } from 'react-redux';

import { RootState } from '../app/store';
import { pause as pauseSpotify, getMostRecentURI } from '../app/spotify';
import { GameStatus, resume, pause, resign, getKnownURIs, getRemainingMs, getDuration, clear } from '../app/round';
import { getLocalSuggestion, getRemoteSuggestions, suggest } from '../app/room';
import GameControls, { Status } from '../components/GameControls';
import Entry from '../components/Entry';
import Songs from '../components/Songs';

interface Props {
  isPlaying: boolean;
  hasStarted: boolean;
  gameOver: boolean;
  songs: Song[];
  guess: string;
  guesses: Guess[];
  guessed: string[];
  playing?: string;
  remainingSeconds: number;
  totalSeconds: number;

  onPlay: () => void;
  onPause: () => void;
  onResign: () => void;
  onReset: () => void;
  onGuess: (guess: string) => void;
}

interface Guess {
  key: string;
  who: string;
  says: string;
}

interface Song {
  uri: string;
  name: string;
  artists: Array<string>;
  start_ms: number;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  contentContainer: {
    marginTop: theme.spacing(2),
  },
  entryBox: {
    position: 'sticky',
    top: theme.spacing(2),
  },
  returnButton: {
    margin: theme.spacing(2, 0, 0)
  },
}));

export const Host = ({
  isPlaying,
  remainingSeconds,
  totalSeconds,
  gameOver,
  onReset,
  ...props}: Props
) => {


  const classes = useStyles();

  return (
    <Container maxWidth='md'>
      <Grid container spacing={2}>
        <Grid item sm={5} xs={12} className={classes.contentContainer}>
          <Box className={classes.entryBox}>
            <Grid container spacing={1}>
              {gameOver && (
                <Grid item xs={12}>
                  <Paper>
                    <Button fullWidth onClick={onReset}>
                      Back to setup
                    </Button>
                  </Paper>
                </Grid>
              )}

              <Grid item xs={12}>
                <GameControls
                  score={props.guessed.length}
                  songCount={props.songs.length}
                  remainingSeconds={remainingSeconds}
                  durationSeconds={totalSeconds}
                  status={gameOver ? Status.GameOver : isPlaying ? Status.Playing : Status.Paused}
                  onToggle={isPlaying ? props.onPause : props.onPlay}
                  onResign={props.onResign}
                />
              </Grid>

              <Grid item xs={12}>
                <Entry
                  localGuess={props.guess || ''}
                  isPlaying={isPlaying}  
                  guesses={props.guesses}
                  onEntry={props.onGuess}
                />
              </Grid>
            </Grid>


          </Box>
        </Grid>
        <Grid item sm={7} xs={12} className={classes.contentContainer}>
          <Songs />
        </Grid>
      </Grid>
    </Container>
  );
};

const reduxStateToGameProps = (state: RootState) => ({
  isPlaying: state.round.status === GameStatus.Playing,
  hasStarted: state.round.status !== GameStatus.Unstarted,
  gameOver: state.round.status !== GameStatus.Unstarted && state.round.status !== GameStatus.Playing && state.round.status !== GameStatus.Paused,
  songs: state.round.tracks,
  playing: getMostRecentURI(state),
  guess: getLocalSuggestion(state),
  guesses: getRemoteSuggestions(state).map(({ says, who: { name, id } }) => ({ says, who: name, key: id })),
  guessed: getKnownURIs(state),
  remainingSeconds: Math.round((getRemainingMs(state) || 0) / 1000),
  totalSeconds: (getDuration(state) || 0) / 1000,
});

const mapDispatchToProps = (dispatch: any) => ({
  onPlay: () => { dispatch(resume()) },
  onPause: () => { dispatch(pause()) },
  onResign: () => { dispatch(resign()) },
  onGuess: (entry: string) => { dispatch(suggest(entry)) },
  onReset: () => { dispatch(pauseSpotify()); dispatch(clear()); },
});

const BoundHost = connect(reduxStateToGameProps, mapDispatchToProps)(Host);
export default BoundHost;

import { createReducer, createAction } from '@reduxjs/toolkit';
// @ts-ignore
import load from "load-script";
import SpotifyWebApi from 'spotify-web-api-js';

import { RootState } from './store';

// type dispatchFunc = typeof store.dispatch;
type dispatchFunc = any;
type stateFunc = () => RootState;

enum Kind {
  Init = 'initialization',
  Auth = 'authentication',
  Account = 'account',
  Playback = 'playback',
}

export interface State {
  inited: boolean;
  token?: string;
  auth_redirect?: string;
  device_id?: string;
  error_msg?: string;
  active_track?: string;
  last_active_track?: string;
  updated_instant?: number;
}

export const isAuthenticated = (state: RootState) => state.spotify.token !== undefined;
export const getAuthRedirect = (state: RootState) => state.spotify.auth_redirect || '';
export const getMostRecentURI = (state: RootState) => state.spotify.last_active_track;
export const getActiveTrack = (state: RootState) => state.spotify.active_track;
export const getIsPlaying = (state: RootState) => getActiveTrack(state) !== undefined;

type Config = LoggedInConfig|LoggedOutConfig;

interface LoggedInConfig {
  token: string;
  expires_at: string;
  auth_redirect: string;
  is_authenticated: true;
}

interface LoggedOutConfig {
  auth_redirect: string;
  is_authenticated: false;
}

export function initSpotify(configEndpoint: string) {
  return async (dispatch: dispatchFunc) => {
    let resp = await fetch(configEndpoint, {credentials: 'include'});
    let config = await resp.json() as Config;

    dispatch(updateConfig(config));

    if (config.is_authenticated) {
      // The server controls the delay.
      let delay = Date.parse(config.expires_at) - Date.now();

      console.log("Delay", delay);
      setTimeout(() => {
        console.log("Refreshing config/token...");
        dispatch(initSpotify(configEndpoint));
      }, delay);
    }
  };
};

export const updateConfig = createAction('spotify/updateConfig', (config: Config) => ({
  payload: {
    config,
  },
}));

export const playing = createAction('spotify/playing', (uri: string, at: number = Date.now()) => ({
  payload: {
    uri,
    at,
  },
}))

export const stopped = createAction('spotify/stopped', (at: number = Date.now()) => ({
  payload: {
    at,
  },
}));

export function play(uri: string, start_ms: number) {
  return (dispatch: dispatchFunc, getState: stateFunc) => {
    const { token, device_id } = getState().spotify;

    if (token === undefined) {
      return;
    }

    // Setup and start playing this track.
    let sdk = new SpotifyWebApi();
    sdk.setAccessToken(token);

    sdk.play({
      device_id: device_id,
      uris: [uri],
      position_ms: start_ms,
    });

    // Update the state store that we've started this track.
    dispatch(playing(uri));
  };
};

export function pause() {
  return (dispatch: dispatchFunc, getState: stateFunc) => {
    const { token } = getState().spotify;

    if (token === undefined) {
      return;
    }

    // Setup and stop playing this track.
    let sdk = new SpotifyWebApi();
    sdk.setAccessToken(token);
    sdk.pause();

    dispatch(stopped());
  };
};

export const clear = createAction('spotify/clear');

export const playerError = createAction('spotify/player/error', (kind: Kind, {message}: {message: string;}) => ({
  payload: {
    kind,
    message,
  },
}));

export const playerReady = createAction('spotify/player/ready', (device_id: string) => ({
  payload: {
    device_id,
  },
}));

export const reducer = createReducer({
  inited: false,
} as State, builder => {
  builder
  .addCase(updateConfig, (state, action) => {
    state.auth_redirect = action.payload.config.auth_redirect;

    if (action.payload.config.is_authenticated) {
      state.token = action.payload.config.token;
    }
  })
  .addCase(playerError, (state, action) => {
    state.inited = true;
    state.error_msg = action.payload.message;
  })
  .addCase(playerReady, (state, action) => {
    state.inited = true;
    state.device_id = action.payload.device_id;
  })
  .addCase(playing, (state, action) => {
    state.active_track = action.payload.uri;
    state.last_active_track = action.payload.uri;
  })
  .addCase(stopped, (state) => {
    state.active_track = undefined;
  })
  .addCase(clear, (state) => {
    state.active_track = undefined;
    state.last_active_track = undefined;
  });
});

export default reducer;

interface ViableStore {
  subscribe: (arg0: () => void) => void;
  getState: () => RootState;
  dispatch: (arg0: any) => void;
}

export const registerBindings = (store: ViableStore, volumeSelector: (arg0: RootState) => number) => {
  // We want to load the SDK once.
  let sdkLoaded = false;
  // When the volume changes, we need that.
  let currentVolume = 0;
  let setVolume = (vol: number) => {};

  store.subscribe(() => {
    const newVolume = volumeSelector(store.getState());

    if (currentVolume === newVolume) {
      return;
    }

    setVolume(newVolume);
    currentVolume = newVolume;

    if (!sdkLoaded && store.getState().spotify.token !== undefined) {
      console.log("Loading Spotify player...")
      load("https://sdk.scdn.co/spotify-player.js");
      sdkLoaded = true;
    }
  });

  window.onSpotifyWebPlaybackSDKReady = () => {
    const player = new Spotify.Player({
      name: "Sporclfy",
      // This probably will need to fetch from the state store directly to handle refreshing when we get there.
      // @ts-ignore
      getOAuthToken: cb => { cb(store.getState().spotify.token); },
    });

    player.addListener('initialization_error', info => { store.dispatch(playerError(Kind.Init, info)); });
    player.addListener('authentication_error', info => { store.dispatch(playerError(Kind.Auth, info)); });
    player.addListener('account_error', info => { store.dispatch(playerError(Kind.Account, info)); });
    player.addListener('playback_error', info => { store.dispatch(playerError(Kind.Playback, info)); });

    player.addListener('ready', info => {
      player.setVolume(volumeSelector(store.getState()));
      store.dispatch(playerReady(info.device_id));
    });

    player.connect();

    // Expose binding for volume.
    setVolume = vol => {
      player.setVolume(vol);
    };
  };
};

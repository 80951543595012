import { createAction, createReducer } from '@reduxjs/toolkit';
import equal from 'deep-equal';

import { RootState } from './store';
import { randomAlphanumeric } from '../random';

const LocalsStorageKey = 'settings';
const DefaultName = 'You';

interface Settings {
  deviceId: string;
  name?: string;
  trackDuration_ms: number;
  gapDuration_ms: number;
  replayTime_ms: number;
  volume: number;
  shuffle: boolean;
}

enum LocalStorageStatus {
  Found = 'found',
}

interface FoundLocalStorageSettings extends Settings {
  found?: LocalStorageStatus.Found;
}

interface MissingLocalStorageSettings {
  found: undefined;
}

type LocalStorageSettings = FoundLocalStorageSettings | MissingLocalStorageSettings;

// Load the settings from local storage.
const loadSettings = (): Settings => {
  const settings = JSON.parse(localStorage.getItem(LocalsStorageKey) || '{}') as LocalStorageSettings;

  // If we don't have the settings, then we need to setup the defaults.
  if (settings.found === undefined) {
    return {
      deviceId: randomAlphanumeric(64),
      trackDuration_ms: 7000,
      gapDuration_ms: 3000,
      replayTime_ms: 600000,
      volume: 0.15,
      shuffle: true,
    };
  }
  
  return settings;
};

const createUpdateActionName = (key: string) => `settings/update/${key}`;

export const getDeviceId = (state: RootState) => state.settings.deviceId;
export const hasName = (state: RootState) => state.settings.name !== undefined;
export const getName = (state: RootState) => state.settings.name || DefaultName;
export const getDuration = (state: RootState) => state.settings.trackDuration_ms;
export const getGap = (state: RootState) => state.settings.gapDuration_ms;
export const getReplay = (state: RootState) => state.settings.replayTime_ms;
export const getVolume = (state: RootState) => state.settings.volume;
export const getShuffle = (state: RootState) => state.settings.shuffle;

const getLocalStorageFormattedBlob = (state: RootState): FoundLocalStorageSettings => ({
  found: LocalStorageStatus.Found,
  deviceId: getDeviceId(state),
  name: getName(state),
  trackDuration_ms: getDuration(state),
  gapDuration_ms: getGap(state),
  replayTime_ms: getReplay(state),
  volume: getVolume(state),
  shuffle: getShuffle(state),
});

export const updateName = createAction(createUpdateActionName('name'), (name: string) => ({
  payload: {
    name,
  },
}));

export const updateDuration = createAction(createUpdateActionName('duration'), (ms: number) => ({
  payload: {
    ms,
  },
}));

export const updateGap = createAction(createUpdateActionName('gap'), (ms: number) => ({
  payload: {
    ms,
  },
}));

export const updateReplay = createAction(createUpdateActionName('replay'), (ms: number) => ({
  payload: {
    ms,
  },
}));

export const updateVolume = createAction(createUpdateActionName('volume'), (value: number) => ({
  payload: {
    value,
  },
}));

export const updateShuffle = createAction(createUpdateActionName('shuffle'), (shuffle: boolean) => ({
  payload: {
    shuffle,
  },
}));

interface Subscribeable {
  getState: () => RootState;
  subscribe: (arg0: () => void) => void;
};

export const registerBindings = (store: Subscribeable) => {
  let lastSeen = {};
  store.subscribe(() => {
    const newSettings = getLocalStorageFormattedBlob(store.getState());

    if (equal(lastSeen, newSettings)) {
      return;
    }

    localStorage.setItem(LocalsStorageKey, JSON.stringify(newSettings));
    lastSeen = newSettings;
  });
};

const reducer = createReducer(loadSettings(), builder => {
    builder
    .addCase(updateName, (state, action) => {
      state.name = action.payload.name;
    })
    .addCase(updateDuration, (state, action) => {
      state.trackDuration_ms = action.payload.ms;
    })
    .addCase(updateGap, (state, action) => {
      state.gapDuration_ms = action.payload.ms;
    })
    .addCase(updateReplay, (state, action) => {
      state.replayTime_ms = action.payload.ms;
    })
    .addCase(updateVolume, (state, action) => {
      state.volume = action.payload.value;
    })
    .addCase(updateShuffle, (state, action) => {
      state.shuffle = action.payload.shuffle;
    });
  }
);

export default reducer;

import React from 'react';
import { Typography, Tooltip, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { RootState } from '../app/store';
import { getRoomCode } from '../app/room';

interface Props {
    className?: string;
    roomCode?: string;
}

export const RoomCode = ({roomCode, className}: Props) => {
  if (roomCode === undefined) {
      return <></>;
  }

  return (
    <Box className={className}>
      <Tooltip title='Room Code'>
          <Typography className={ className } variant='h6'>
            { roomCode }
          </Typography>
        </Tooltip>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  roomCode: getRoomCode(state),
});

export const BoundRoomCode = connect(mapStateToProps)(RoomCode);
export default BoundRoomCode;

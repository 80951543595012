import React from 'react';
import { Grid, Container, TextField, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { join as joinRoom } from '../app/room';

interface Props {
  joinRoom: (arg0: any) => {};
  randomRoom: () => {};
}

export const Join = ({joinRoom, randomRoom}: Props) => {
  let roomName: string;

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            fullWidth
            label='Room Code'
            onChange={ (e) => { roomName = e.target.value; } }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color='primary'
            variant='contained'
            onClick={ () => joinRoom(roomName) }>
              Join
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={ randomRoom }>
              New Room
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  joinRoom: (code: string) => dispatch(joinRoom(code)),
  randomRoom: () => dispatch(joinRoom()),
});

const BoundJoin = connect(null, mapDispatchToProps)(Join);
export default BoundJoin;

import React from 'react';
import { Slider } from '@material-ui/core';
import { VolumeUp } from '@material-ui/icons';
import { connect } from 'react-redux';
import { updateVolume } from '../app/settings';
import { RootState } from '../app/store';

interface Props {
    className?: string;
    color?: 'primary'|'secondary';
    volume: number;
    setVolume: (arg0: number) => void;
}

export const VolumeControl = ({volume, setVolume, className, color}: Props) => {
  const onChange = (e: any, val: number|number[]) => {
    if (val instanceof Array) {
        val = val[0];
    }

    setVolume(val);
  }

  return (
    <>
      <VolumeUp />
      <Slider
        className={className}
        color={ color ===  undefined ? 'secondary' : color}
        min={0}
        step={0.01}
        max={1}
        value={ volume }
        onChange={ onChange }
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  volume: state.settings.volume,
});

const mapDispatchToProps = (dispatch: any) => ({
  setVolume: (vol: number) => dispatch(updateVolume(vol)),
});

export const BoundVolumeControl = connect(mapStateToProps, mapDispatchToProps)(VolumeControl);
export default BoundVolumeControl;
